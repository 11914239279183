import Cookies from 'js-cookie'

const TokenKey = 'Authorization'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, 86400)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setCookie(key, value) {
  return Cookies.set(key, value, 86400)
}
export function getCookie(key) {
  return Cookies.get(key)
}