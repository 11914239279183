<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

import {getUrlKey} from "@/utils/utils";

export default {
    name:'app',
    mounted() {
	    this.initApp()
	    
	    //获取url参数saasId
	    let saasId = getUrlKey('saasId')
	    if (saasId) {
		    this.$cookies.set('saasId', saasId)
	    }
	    
    },
    methods: {
      initApp() {},
	    
	    async searchDomInTree(keyword, root = null) {
		    if (!root) {
			    root = window.vueIns
		    }
		    if (root.$options._componentTag === keyword) {
			    return root
		    } else {
			    let domList = root.$children
			    for (let i = 0; i < domList.length; i++) {
				    let result = await this.searchDomInTree(keyword, domList[i])
				    if (result) {
					    return result
				    }
			    }
		    }
	    },
	    windowProxy(method, path, data) {
		    return this.$http[method](path, data).then(res => {
					return res
		    })
	    },
    },
}
</script>

<style>
</style>
