import { setToken, getToken } from '@/utils/auth'
const token = getToken()
const state = {
  token: token, // 其他用户信息
  roles: [], //
  username: token || ''
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_ROlES: (state, roles) => {
    state.roles = roles
  },
  UPDATE: (state, payload) => {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key]
    })
  }
};

const actions = {
  // 模拟用户登录
  login({ commit }, userInfo) {
    const { username } = userInfo
    return new Promise((resolve, reject) => {
      // setTimeout(() => {
      if (username === 'admin' || username === 'hao') {
        // commit('SET_TOKEN', username)
        commit('UPDATE', {
          token: username,
          username
        })
        sessionStorage.setItem('token', username)
        setToken(username)
        resolve(username)
      } else {
        reject('用户名、秘密错误')
      }
      // }, 1000)
    })
  },
  // 模拟获取用户信息，角色和令牌内容一致
  getInfo({ commit, state }) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const roles = state.token === 'admin' ? ['admin'] : ['editor']
        // commit('SET_ROLES', roles)
        commit('UPDATE', {
          roles
        })
        resolve({ roles })
      }, 1000)
    })
  },
  resetToken({ commit }) {
    return new Promise(resolve => {
      // commit("SET_TOKEN", ""); 
      // commit("SET_ROLES", []);
      commit('UPDATE', {
        token: '',
        roles: []
      })
      sessionStorage.removeItem('token')
      resolve()
    })
  }
}



export default {
  namespaced: true,
  state,
  mutations,
  actions
};