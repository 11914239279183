import Vue from 'vue'
import Router from 'vue-router'
import {getCookie} from "@/utils/auth";

import {
  Message,
} from "element-ui";

const layout = () => import('@/layout/index.vue')

let route = getCookie('userType') !== 'admin' ? [
  {
    path: '/login',
    component: () => import('@/page/client/login.vue'),
    hidden: true
  },
  {
    path: '/adminLogin',
    component: () => import('@/page/admin/login.vue'),
    hidden: true
  },
  {
    path: '/saasLogin',
    component: () => import('@/page/saas/login.vue'),
    hidden: true
  },
  {
    path: '/redirect',
    component: layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/components/redirect.vue')
      }
    ]
  },
  {
    path: '/',
    beforeEnter() { window.location.href = process.env.VUE_APP_CONTROL_PAGE; this.$forceUpdate() },
    meta: {
      title: '数据中心',
    }
  },
  {
    path: '/driver',
    component: layout,
    meta: {
      title: '授权绑定',
    },
    children: [
      {
        path: 'driver',
        component: () => import('@/page/client/driver/driverList.vue'),
        name: 'driver',
        meta: {
          title: '授权绑定',
        }
      },
      {
        path: 'driverDetail',
        component: () => import('@/page/client/driver/driverDetail.vue'),
        name: 'driverDetail',
        hidden: true,
        meta: {
          title: '平台详情',
        }
      },
    ]
  },

  {
    path: '/generate',
    component: layout,
    meta: {
      title: '智能助手',
    },
    children: [
      {
        path: 'generate',
        component: () => import('@/page/client/generate/generate.vue'),
        name: 'user-list',
        meta: {
          title: '内容生成',
        }
      },
      {
        path: 'preview',
        component: () => import('@/page/client/generate/articlePreview.vue'),
        name: 'article-preview',
        meta: {
          title: '预览',
        },
        hidden: true
      }
    ]
  },
  {
    path: '/smartGenerate',
    component: layout,
    meta: {
      title: '智能助手',
    },
    children: [
      {
        path: 'smartGenerate',
        component: () => import('@/page/client/generate/smartGenerate.vue'),
        name: 'smartRelease',
        meta: {
          title: '批量生成',
        }
      },
    ]
  },
  {
    path: '/generateList',
    component: layout,
    meta: {
      title: '智能助手',
    },
    children: [
      {
        path: 'generateList',
        component: () => import('@/page/client/generate/list.vue'),
        name: 'release-page',
        meta: {
          title: '成品列表',
        }
      }
    ]
  },
  {
    path: '/wallet',
    component: layout,
    hidden: false,
    meta: {
      title: '钱包',
    },
    children: [
      {
        path: 'charge',
        component: () => import('@/page/client/wallet/charge.vue'),
        name: 'charge',
        hidden: true,
        meta: {
          title: '充值',
        }
      },
      {
        path: 'record',
        component: () => import('@/page/client/wallet/record.vue'),
        name: 'record',
        meta: {
          title: '金币记录',
        }
      }
    ]
  },
  {
    path: '/addSuggest',
    component: layout,
    hidden: true,
    meta: {
      title: '反馈',
    },
    children: [
      {
        path: 'addSuggest',
        component: () => import('@/page/client/addSuggest.vue'),
        name: 'addSuggest',
        meta: {
          title: '提交反馈',
        }
      },
    ]
  },
  {
    path: '/article2video',
    component: layout,
    beforeEnter() { Message({message: "仅内测用户可打开", type: "success"});},
    meta: {
      title: '图文转视频',
    },
    children: [
      {
        path: 'article2video',
        component: () => import('@/page/client/addSuggest.vue'),
        name: 'article2video',
        meta: {
          title: '图文视频',
        }
      },
    ]
  },
  {
    path: '/localImg',
    component: layout,
    meta: {
      title: '本地素材',
    },
    children: [
      {
        path: 'localImg',
        component: () => import('@/page/client/material/list.vue'),
        name: 'localImg',
        meta: {
          title: '本地素材',
        }
      }
    ]
  },
  {
    path: '/AITruncate',
    component: layout,
    beforeEnter() { Message({message: "仅内测用户可打开", type: "success"});},
    meta: {
      title: 'AI混剪',
    },
    children: [
      {
        path: 'AITruncate',
        component: () => import('@/page/client/addSuggest.vue'),
        name: 'AITruncate',
        meta: {
          title: 'A I 混剪',
        }
      },
    ]
  },
  {
    path: '/artQrCode',
    component: layout,
    beforeEnter() { Message({message: "仅内测用户可打开", type: "success"});},
    meta: {
      title: '艺术二维码',
    },
    children: [
      {
        path: 'artQrCode',
        component: () => import('@/page/client/addSuggest.vue'),
        name: 'artQrCode',
        meta: {
          title: '艺术二维码',
        }
      },
    ]
  },




] : [


//平台账户
  {
    path: '/login',
    component: () => import('@/page/client/login.vue'),
    hidden: true
  },
  {
    path: '/adminLogin',
    component: () => import('@/page/admin/login.vue'),
    hidden: true
  },
  {
    path: '/saasLogin',
    component: () => import('@/page/saas/login.vue'),
    hidden: true
  },
  {
    path: '/',
    redirect: '/admin/userList',
    hidden: true,
    meta: {
      title: '管理首页',
    }
  },
  {
    path: '/admin',
    component: layout,
    meta: {
      title: '后台管理页',
    },
    children: [
      {
        path: 'userList',
        component: () => import('@/page/admin/userList/list.vue'),
        name: 'user-list',
        meta: {
          title: '平台账户',
        },
      },
      {
        path: 'addUser',
        component: () => import('@/page/admin/userList/addUser.vue'),
        name: 'add-user',
        hidden: true,
        meta: {
          title: '添加账户',
        }
      },
      {
        path: 'editUser',
        component: () => import('@/page/admin/userList/editUser.vue'),
        name: 'edit-user',
        hidden: true,
        meta: {
          title: '编辑账户',
        }
      }
    ]
  },
  {
    path: '/activationCode',
    component: layout,
    meta: {
      title: '卡密',
    },
    children: [
      {
        path: 'list',
        component: () => import('@/page/admin/activationCode/record.vue'),
        name: 'record',
        meta: {
          title: '我的卡密',
        }
      }
    ]
  },
  {
    path: '/suggest',
    component: layout,
    meta: {
      title: '反馈列表',
    },
    children: [
      {
        path: 'list',
        component: () => import('@/page/admin/suggestList.vue'),
        name: 'interface',
        meta: {
          title: '反馈列表',
        }
      },
    ]
  },
  {
    path: '/settingAi',
    component: layout,
    meta: {
      title: 'AI配置',
    },
    children: [
      {
        path: 'settingAi',
        component: () => import('@/page/admin/settingAi.vue'),
        name: 'version',
        meta: {
          title: 'AI配置',
        }
      },
    ]
  },
  {
    path: '/settingBase',
    component: layout,
    meta: {
      title: '基础配置',
    },
    children: [
      {
        path: 'settingBase',
        component: () => import('@/page/admin/settingBase.vue'),
        name: 'version',
        meta: {
          title: '基础配置',
        }
      },
    ]
  },
  {
    path: '/settingLogin',
    component: layout,
    meta: {
      title: '登录配置',
    },
    children: [
      {
        path: 'settingLogin',
        component: () => import('@/page/admin/settingLogin.vue'),
        name: 'version',
        meta: {
          title: '登录配置',
        }
      },
    ]
  },
  {
    path: '/settingPay',
    component: layout,
    meta: {
      title: '收款配置',
    },
    children: [
      {
        path: 'settingPay',
        component: () => import('@/page/admin/settingPay.vue'),
        name: 'version',
        meta: {
          title: '收款配置',
        }
      },
    ]
  },
  {
    path: '/download',
    component: layout,
    meta: {
      title: '下载客户端',
    },
    children: [
      {
        path: 'download',
        component: () => import('@/page/admin/downloadClient.vue'),
        name: 'version',
        meta: {
          title: '下载客户端',
        }
      },
    ]
  },
  {
    path: '/addSuggest',
    component: layout,
    hidden: true,
    meta: {
      title: '反馈',
    },
    children: [
      {
        path: 'addSuggest',
        component: () => import('@/page/client/addSuggest.vue'),
        name: 'addSuggest',
        meta: {
          title: '提交反馈',
        }
      },
    ]
  },
]

//如果是超级管理员
if (getCookie('userType') === 'superAdmin') {
  route = [
    {
      path: '/login',
      component: () => import('@/page/client/login.vue'),
      hidden: true
    },
    {
      path: '/adminLogin',
      component: () => import('@/page/admin/login.vue'),
      hidden: true
    },
    {
      path: '/saasLogin',
      component: () => import('@/page/saas/login.vue'),
      hidden: true
    },
    {
      path: '/',
      redirect: '/saas/userList',
      hidden: true,
      meta: {
        title: '管理首页',
      }
    },
    {
      path: '/saas',
      component: layout,
      meta: {
        title: '后台管理页',
      },
      children: [
        {
          path: 'userList',
          component: () => import('@/page/saas/userList/list.vue'),
          name: 'user-list',
          meta: {
            title: 'SAAS账户',
          },
        },
        {
          path: 'addUser',
          component: () => import('@/page/saas/userList/addUser.vue'),
          name: 'add-user',
          hidden: true,
          meta: {
            title: '添加账户',
          }
        },
        {
          path: 'editUser',
          component: () => import('@/page/saas/userList/editUser.vue'),
          name: 'edit-user',
          hidden: true,
          meta: {
            title: '编辑账户',
          }
        }
      ]
    },
    {
      path: '/config',
      component: layout,
      meta: {
        title: '配置',
      },
      children: [
        {
          path: 'config',
          component: () => import('@/page/saas/setting.vue'),
          name: 'interface',
          meta: {
            title: '授权激活',
          }
        },
      ]
    },
    {
      path: '/version',
      component: layout,
      meta: {
        title: '软件版本',
      },
      children: [
        {
          path: 'list',
          component: () => import('@/page/saas/version.vue'),
          name: 'version',
          meta: {
            title: '软件版本',
          }
        },
      ]
    },
    {
      path: '/update',
      component: layout,
      meta: {
        title: '软件更新',
      },
      children: [
        {
          path: 'update',
          component: () => import('@/page/saas/update.vue'),
          name: 'version',
          meta: {
            title: '软件更新',
          }
        },
      ]
    }
  ]
}

export const constRoutes = route
export const asyncRoutes = []

Vue.use(Router)

export default new Router({
  mode: 'hash',
  routes: [...constRoutes, ...asyncRoutes],
  base: process.env.BASE_URL
}) 