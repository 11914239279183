import Vue from "vue";
import ElementUI from "element-ui";
import ElDataTable from "@femessage/el-data-table";
import ElFormRenderer from "@femessage/el-form-renderer";
import {
  Message,
  MessageBox,
  Pagination,
  Table,
  TableColumn,
  Form,
  FormItem,
} from "element-ui";

import "./styles/element-variables.scss";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import vPermission from "@/directive/permission";
import "@/styles/index.scss";
import "@/assets/global.scss";
import "@/icons";
import "./permission";
import service from '@/utils/request.js'

Vue.use(ElementUI, {
  size: "mini", // set element-ui default size
});
Vue.component("el-form-renderer", ElFormRenderer);
Vue.component("el-data-table", ElDataTable);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Form);
Vue.use(FormItem);
Vue.directive("permission", vPermission);
Vue.config.productionTip = false;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;

Vue.prototype.$http = service;

let vueIns = new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
window.vueIns = vueIns;