import axios from 'axios'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'
import {getCookie, getRsaCode} from "@/utils/utils";
import chunk from "lodash/chunk";
import Vue from 'vue'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 180000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  async config => {
    config.withCredentials = true;
    const now = Date.now();
    config.data = config.data ?? {};
    config.data._timestamp = now;
    config.data.saasId = getCookie('saasId');

    if (+process.env.VUE_APP_API_ENCRYPT) {
      config.data = JSON.stringify(config.data);
      const str = config.data;
      const arr = str.split("");
      const chunkArrs = chunk(arr, 20);
      const chunkEncryptStrArrs = await Promise.all(
        chunkArrs.map(item => getRsaCode(item.join("")))
      );
      config.data = chunkEncryptStrArrs.join("");

    } else if (config.url === '/common/upload') {

      config.headers["Content-Type"] = "application/x-www-form-urlencoded; charset=utf-8";

    } else {

      config.data = JSON.stringify(config.data);
      config.headers["Content-Type"] = "application/json; charset=utf-8";
    }

    config.headers['Authorization'] = `${getToken()}`
    return config;
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)


// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0) {
      if (res.url && res.url.length > 1) {
        return res
      }

      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      if (res.code === -412 || res.code === -413) {
        // to re-login
        Message({
          message: res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
        Vue.$cookies.remove('Authorization')
        window.location.reload()
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
