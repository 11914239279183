import JSEncrypt from 'jsencrypt'
import VueCookies from 'vue-cookies'
import Vue from 'vue'
import chunk from 'lodash/chunk'
let NodeRSA = require('node-rsa')
Vue.use(VueCookies)


const encryptStr = new JSEncrypt()
encryptStr.setPublicKey(process.env.VUE_APP_PUB_KEY) // 设置 加密公钥

/**
 * 加密字符串,分片
 * @param str
 * @returns {string | false}
 */
export const getRsaCode = (str) => {
  return encryptStr.encrypt(str.toString())
}

export const stringToHex = function (str) {
  let val = ''
  for (let i = 0; i < str.length; i++) {
    if (val === '') val = str.charCodeAt(i).toString(16)
    else val += '' + str.charCodeAt(i).toString(16)
  }
  return val
}

export const publicRsaDecode = (str) => {
  let key = new NodeRSA(process.env.VUE_APP_PUB_KEY) // 设置你的公钥
  return key.decryptPublic(str.toString(), 'utf-8')
}

export const getCookie = function(key) {
  return Vue.$cookies.get(key)
}

export const setCookie = function (key, val) {
  Vue.$cookies.set(key, val)
}

export const RsaEncode = function (params) {
  //加密传输
  const str = JSON.stringify(params)
  const arr = str.split('')
  const chunkArrs = chunk(arr, 80)

  let string = ''
  chunkArrs.map((item) => (string += getRsaCode(item.join(''))))
  return string
}

export const RsaDecode = function (params) {
  const arr = params.split('')
  const chunkArrs = chunk(arr, 344)
  let string = ''
  chunkArrs.map((item) => (string += publicRsaDecode(item.join(''))))
  return string
}

export const isPcClient = function() {
  if (typeof pywebview === 'undefined') {
    return false
  }
  return true
}

export const getApiBaseUrl = function() {
  return process.env.VUE_APP_BASE_API
}

export const getUrlKey = function(name){
  return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
}
export const isVideo = function(str) {
  return /\.(mp4|avi|mov|wmv|flv|mkv|webm|mpeg|mpg|3gp)$/i.test(str)
}

export const isImage = function(str) {
  return /\.(jpg|jpeg|png|gif|bmp|tiff|tif|svg|webp|heif|heic|ico)$/i.test(str)
}