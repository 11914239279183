import router from './router'
// import store from './store'
import {getCookie, getToken} from '@/utils/auth'

const whiteList = ['/login', '/adminLogin', '/saasLogin']  // 无需令牌白名单

// 全局守卫
router.beforeEach(async (to, form, next) => {
  // 获取令牌判断用户是否登录
  const hasToken = getToken()
  // 已登录
  if (hasToken) {
    if (to.path === '/login') {
      // 若已登录没有必要显示登录页，重定向至首页
      next({
        path: '/'
      })
    } else {
      // const hasRoles = store.state.user.roles && store.state.user.roles.length > 0
      // if (hasRoles) {
      //   next()
      // } else {
      //   try {
      //     // 先请求获取用户信息
      //     const { roles } = await store.dispatch('user/getInfo')
      //     // 动态生成
      //     const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
      //     console.log(accessRoutes)
      //     // 添加这些路由到路由器
      //     router.addRoutes(accessRoutes)
      //     // 路由再进来一次
          next()

      //   } catch (error) {
      //     // 出错需重置令牌并重新登录（令牌过期、网络错误等原因） 
      //     await store.dispatch('user/resetToken')
      //     next(`/login?redirect=${to.path}`)
      //     console.error(error || '未知错误')
      //   }
      // }
    }
  } else {
    // 未登录
    if (whiteList.indexOf(to.path) !== -1) {
      // 白名单中的路由放过
      next()
    } else {
      // 重定向至登录页
      let loginPath = '/login'
      if (getCookie('userType') === 'admin') {
        loginPath = '/adminLogin'
      } else if (getCookie('userType') === 'superAdmin') {
        loginPath = '/saasLogin'
      }
      next( `${loginPath}?redirect=${to.path}`)
    }
  }
})