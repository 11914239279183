
const state = {
  sidebar: {
    opened: sessionStorage.getItem('sidebarStatus') ? !!+sessionStorage.getItem('sidebarStatus') : true,
    withoutAnimation: false
  }
}

const mutations = {
  UPDATE: (state, payload) => {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key]
    })
  },
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      sessionStorage.getItem('sidebarStatus', 1)
    } else {
      sessionStorage.getItem('sidebarStatus', 0)
    }
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}